import React, {useContext} from 'react';
import {Grid, Typography, Box} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Sidebar } from './components/index.js';
import {useParams} from "react-router";
import FAQItemList from "./components/FAQItemList";
import SpecificFAQManagement from "../../component/App/FAQManagementScreen/SpecificFAQManagement";
import FAQManagementList from "../../component/App/FAQManagementScreen/FAQManagementList";
import {DemoContext} from "../../lib/contexts/DemoContext";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  mobileScreen:{
    width:'100%',
    maxWidth:'100%',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  mobileContainer:{
    width:'100%',
    maxWidth:'100%',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  containerTop:{
    maxHeight: '78vh',
    overflowY:"auto",
  },
}));

const EmbeddableFAQ = ({management = false}) => {
  const classes = useStyles();
  const params = useParams('faqId')
  const {
    state: { matchSM, matchMD },
  } = useContext(DemoContext);
  
  return (
    <Box>
      <Grid
         container
         item
         xs={12}
         alignItems="center"
         spacing={0}
         className={matchSM ? classes.mobileScreen : classes.screen}
      >
        {!management && <Typography variant={'subtitle1'}>
          Help Center
        </Typography>}
        <Grid
           container
           item xs={12}
           direction="row"
           justifyContent="space-evenly"
           alignItems="flex-start"
           spacing={management ? matchSM ? 2 : 2 : 0}
        className={matchSM ? classes.mobileContainer : classes.container}
        >
          <Grid item xs={management ? matchMD ? 12 : 6 : 4}
                style={{order: matchMD ? 2 : 1}}>
            <Sidebar management={management} />
          </Grid>
          <Grid item xs={management ? matchMD ? 12 : 6 : 8} style={{order: matchMD ? 1 : 2}}>
            {management ?
               params && params.faqId ?
                  <SpecificFAQManagement management={management}/> :
                  <Typography variant={'subtitle1'} style={{marginTop: matchMD ? 0 : 300, marginBottom: matchMD ? 0 : 'inherit', textAlign:'center'}}>Select an item</Typography>
               : <>{!management && <FAQItemList />}</>}
            
          </Grid>
    
        </Grid>
      </Grid>
    </Box>
  );
};
export default EmbeddableFAQ;
