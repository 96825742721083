import {LinearProgress, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import {Help} from "@mui/icons-material";
import React, {useContext, useMemo} from "react";
import {useParams} from "react-router";
import useOrganizationFaqs from "../../../lib/hooks/useOrganizationFaqs";
import {toUnderScoreSlug} from "../../../lib/helpers/helpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";

const useStyles = makeStyles(theme => ({
   screen:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
   },
   container:{
      width:'100%',
      maxWidth:'80vw',
      margin:'auto',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      },
   },
   containerTop:{
      maxHeight: '78vh',
      overflowY:"auto",
   },
}));

const FAQItemList = () => {
   const classes = useStyles();
   const { tag, faqID, question } = useParams();
   const {
      state: { api_key, matchSM },
   } = useContext(DemoContext);
   const { isLoading: isLoadingFaq, data: faqs } = useOrganizationFaqs(api_key);
   
   const filteredData = useMemo(() => {
      const filteredMostPopularHashtags = faqs?.filter(({ hashtags = [] }) => hashtags.some(hashtag => toUnderScoreSlug(hashtag).includes(toUnderScoreSlug(tag))))
         .map(item => ({ ...item, hashtags: item.hashtags.map(hashtag => toUnderScoreSlug(hashtag)) }))
         .filter(item => item.hashtags.some(slug => slug === toUnderScoreSlug(tag))) ?? [];
      const filteredFaqs = question ? faqs?.filter(item => toUnderScoreSlug(item.question).includes(toUnderScoreSlug(question))) ?? [] : [];
      return filteredMostPopularHashtags.length || filteredFaqs.length ? [...filteredMostPopularHashtags, ...filteredFaqs] : faqs ?? [];
   }, [faqs, question, tag]);

return (<>
   <Typography component={'h2'} style={{textAlign:'center'}}>
   Browse our Help Articles and Frequently Asked Questions
</Typography>
<Paper style={{padding: matchSM ? 20 : 20}}>
   {isLoadingFaq && <LinearProgress/>}
   <List component="nav" aria-label="faq sidebar list"
         className={classes.containerTop}>
      {filteredData && filteredData.map(item=><ListItem
         key={item.id}
      >
         <ListItemIcon>
            <Help className={classes.icon}/>
         </ListItemIcon>
         <ListItemText primary={item.question} secondary={item.answer} />
      </ListItem>)}
   </List>
</Paper>
   </>
)
};
export default FAQItemList;
