import React, {useRef, useState, useEffect, useContext, useMemo, useCallback} from 'react';
import {createContext} from 'react';
import {
   Button,
   Card, CardActionArea, CardActions,
   CardContent,
   CardMedia, Divider,
   Drawer, FormControl, Grid, IconButton, InputLabel,
   List,
   ListItem, ListItemText, MenuItem, Select, Slider, TextField, Typography,
} from '@mui/material';
import VideoCameraIcon from '@mui/icons-material/Videocam';
import {Download, Mic, ScreenShare, Stop, VolumeOff, VolumeUp, Info, Menu} from "@mui/icons-material";
import AnimatedLogo from '../AnimatedLogo'
import {makeStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
const useStyles = makeStyles((theme) => ({
   paper: (props) => ({
      bottom: props.openDrawer ? 0 : (props.previewHeightBuffer * -1),
      transition: 'bottom 0.3s ease-out',
      zIndex:1300
   }),
}));
export const StudioContext = createContext({});
const Studio = () => {
   const production = useMemo(()=>window.location.hostname === 'studiocast.fm',[]);
   const [isLoadingApp, setIsLoadingApp] = useState(production? true : false)
   useEffect(() => {
      const timerId = setTimeout(() => {
         setIsLoadingApp(false);
      }, 5000);
      return () => clearInterval(timerId);
   }, []);
   return (
      <StudioContextProvider>
         {isLoadingApp ? <AnimatedLogo/> : <>
            <Grid container spacing={0}>
               <Grid item xs={9} style={{backgroundColor: '#111'}}>
                  <VideoEditor/>
               </Grid>
               <Grid item xs={3}>
                  <StreamSettings/>
               </Grid>
            </Grid>
            <MultiCamera/>
         </>}
      </StudioContextProvider>
   );
}
export const MultiCamera = () => {
   const {
      state: {  videoEditorRef, videoRefs, cameras, cameraStreams, screenStream, screenRef },
      actions: { setCameras, setScreenStream, setCameraStreams, switchCameraStream, setupStream },
   } = useContext(StudioContext);
   const getAvailableCameras = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'videoinput');
   };
   const startScreenSharing = async () => {
      try {
         const stream = await navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true
         });
         setScreenStream(stream);
         //videoEditorRef.current.srcObject = stream;
         //videoEditorRef.current.play();
         setupStream(stream);
      } catch (err) {
         console.error("Error during screen sharing", err);
      }
   };
   const stopScreenSharing = async () => {
      try {
         screenStream.getTracks().forEach(track => track.stop())
         setScreenStream(null);
      } catch (err) {
      }
   };
   const handleCameraClick = (camera) => {
      if (camera.type === 'screen') {
         if (!screenStream || screenStream.active === false) {
            startScreenSharing();
         } else {
            //videoEditorRef.current.srcObject = screenStream;
            //videoEditorRef.current.play();
            setupStream(screenStream)
         }
      } else {
         switchCameraStream(camera);
      }
   };
   
   useEffect(() => {
      if (screenRef.current && screenStream) {
         screenRef.current.srcObject = screenStream;
      }
      if (cameraStreams && cameraStreams.length > 0) {
         cameraStreams.forEach((stream, index) => {
            if (videoRefs.current[index]) {
               videoRefs.current[index].srcObject = stream;
            }
         });
      }
   }, [screenStream, cameraStreams]);
   
   useEffect(() => {
      const initializeCameras = async () => {
         let cameraDevices = await getAvailableCameras();
         let streams = [];
         for (let i = 0; i < cameraDevices.length; i++) {
            let stream = await navigator.mediaDevices.getUserMedia({
               video: {
                  deviceId: cameraDevices[i].deviceId,
                  aspectRatio: 16/9,
               }
            });
            streams.push(stream);
         }
         setCameras(cameraDevices);
         setCameraStreams(streams);
      };
      
      initializeCameras();
   }, []);
   
   const previewWidth = 384;
   const previewHeight = 216;
   const previewHeightBuffer = previewHeight + 75;
   const [openDrawer, setOpenDrawer] = useState(true)
   const classes = useStyles({ openDrawer, previewHeightBuffer });
   
   return (
         <Drawer variant="permanent" anchor="bottom"
                 style={{ width: '100%', position: 'fixed', zIndex:1300, bottom: openDrawer ? 0 : (previewHeight * -1) }}
                 classes={{ paper: classes.paper }} // Override the internal Paper style
         >
            <IconButton onClick={()=>setOpenDrawer(!openDrawer)} style={{ float: 'right', color: '#fff' }}><Menu/></IconButton>
            <List style={{ display: 'flex',
               justifyContent: 'space-between',
               overflowX: 'auto',
               padding:0,
               width: '100%', position:'relative', bottom: openDrawer ? 'initial' : (previewHeightBuffer * -1) }}>
               {cameras.map((camera, index) => (
                  <ListItem key={index} disablePadding>
                     <Card style={{maxHeight:previewHeightBuffer, margin:'auto'}}>
                        <CardMedia style={{maxHeight:previewHeight, backgroundColor:'#000'}}>
                           <video style={{maxHeight:previewHeight}} ref={el => videoRefs.current[index] = el}
                                  autoPlay muted width={previewWidth} srcObject={camera.stream} onClick={() => handleCameraClick(camera)} />
                        </CardMedia>
                        <CardContent>
                           <CardActionArea>
                              <CardActions>
                                 <VideoCameraIcon />&nbsp;
                                 {camera?.label || `Camera ${index}`}
                              </CardActions>
                              
                           </CardActionArea>
                        </CardContent>
                     </Card>
                  </ListItem>
               ))}
               <ListItem disablePadding>
                  <Card style={{maxHeight:previewHeightBuffer, margin:'auto'}}>
                     <CardMedia style={{minWidth:previewWidth, maxHeight:previewHeight, backgroundColor: screenStream ? '#000' : 'transparent'}}>
                        {screenStream ? <video  style={{maxHeight:previewHeight, width:previewWidth}}
                                                ref={el => screenRef.current = el}
                           autoPlay muted width={previewWidth}
                           srcObject={screenStream}
                           onClick={() => handleCameraClick({type: 'screen'})}
                        />
                        :
                           <MonitorIcon onClick={startScreenSharing} width={previewWidth}/>
                        }
                     </CardMedia>
                     <CardContent>
   
                        <CardActions>
                        <CardActionArea onClick={startScreenSharing}>
                              <ScreenShare />&nbsp;
                              Screen Share
                           </CardActionArea>
                           {screenStream && <CardActionArea>
                              <Button variant={'outlined'} onClick={stopScreenSharing}>
                                 <Stop/>&nbsp;
                                 Stop
                              </Button>
                           </CardActionArea>}
                        </CardActions>
                     </CardContent>
                  </Card>
               </ListItem>
            </List>
         </Drawer>
   );
};
export const AudioManager = () => {
   const {
      state: { screenStream, gainNode, audioContext, volumes, audioDevices, audioPermissionGranted },
      actions: { setAudioDevices, setVolumes },
   } = useContext(StudioContext);
   
   useEffect(() => {
      async function fetchDevices() {
         try {
            const deviceList = await navigator.mediaDevices.enumerateDevices();
            setAudioDevices(deviceList.filter(device => device.kind === "audioinput"));
         } catch (error) {
            console.error('Error fetching audio devices:', error);
         }
      }
   
      // Connect screen stream to the audio context via a gain node
      if (screenStream && screenStream.getAudioTracks().length > 0) {
         const source = audioContext.current.createMediaStreamSource(screenStream);
         source.connect(gainNode.current).connect(audioContext.current.destination);
      }
   
      
      fetchDevices();
   }, [audioPermissionGranted, screenStream]);
   
   const handleVolumeChange = (deviceId, value) => {
      setVolumes(prevVolumes => ({
         ...prevVolumes,
         [deviceId]: value
      }));
      gainNode.current.gain.value = value / 100;
   }
   
   return (<Grid item xs={12}>
         <ListItemText primary="Volume" /><br/>
      <List style={{overflowY:'auto', maxHeight:210, marginTop:-25, width:'100%'}}>
         {screenStream && (
            <ListItem>
               <ListItemText primary="Screen Stream" />
               <IconButton edge="start" color="inherit" aria-label="mute" onClick={()=>handleVolumeChange('screenStream', -100)}>
                  <VolumeOff />
               </IconButton>
               <Slider
                  value={volumes['screenStream'] || 50}
                  onChange={(_, newValue) => handleVolumeChange('screenStream', newValue)}
                  aria-labelledby="continuous-slider"
               />
               <IconButton edge="end" color="inherit" aria-label="volume-up" onClick={()=>handleVolumeChange('screenStream', 100)}>
                  <VolumeUp />
               </IconButton>
            </ListItem>
         )}
         {audioDevices.map(device => (
            <ListItem key={device.deviceId}>
               <ListItemText primary={device.deviceId === "default" ? "System Default" : device.label || "Unknown Device"} />
               <IconButton edge="start" color="inherit" aria-label="mute" onClick={()=>handleVolumeChange(device.deviceId, -100)}>
                  <VolumeOff />
               </IconButton>
               <Slider
                  value={volumes[device.deviceId] || 50}
                  onChange={(_, newValue) => handleVolumeChange(device.deviceId, newValue)}
                  aria-labelledby="continuous-slider"
               />
               <IconButton edge="end" color="inherit" aria-label="volume-up" onClick={()=>handleVolumeChange(device.deviceId, 100)}>
                  <VolumeUp />
               </IconButton>
            </ListItem>
         ))}
      </List>
      </Grid>
   );
}
export const StreamSettings = () => {

   return (
      <Drawer anchor="right" variant={'permanent'}>
         <div style={{ height:'100vh'}}>
      <div style={{maxWidth:500}}>
         <CardContent>
            <Grid container spacing={1}>
               <FPSManager/>
               <BitRateManager/>
               <AudioManager />
               <SessionManager/>
            </Grid>
         </CardContent>
      </div>
         </div>
      </Drawer>
   );
};
export const FPSManager = () => {
   const {
      state: { desiredFPS, FPS },
      actions: { setDesiredFPS },
   } = useContext(StudioContext);
   const handleFPSChange = (event) => {
      setDesiredFPS(event.target.value);
   };
   const fpsDifference = FPS - desiredFPS;
   const fpsColor =
      fpsDifference >= -5 && fpsDifference <= 5 ? "green" :
         fpsDifference > 5 && fpsDifference <= 10 ? "orange" :
            fpsDifference < -5 && fpsDifference >= -10 ? "orange" : "red";
   return (
      <>
         <Grid item>
            <FormControl variant="outlined" style={{ marginTop: '10px' }}>
               <InputLabel id="fps-select-label"><span style={{ color: fpsColor }}>
                        {FPS}
                    </span>
                  &nbsp;/ {desiredFPS}</InputLabel>
               <TextField
                  style={{width: 100}}
                  variant={'outlined'}
                  labelId="fps-select-current-label"
                  id="fps-select-current"
                  value={FPS}
                  label="Current FPS"
               >
                  <MenuItem value={FPS}>
               </MenuItem>
               </TextField>
            </FormControl>
         </Grid>
         
{/*
         <Grid item>
            <FormControl variant="outlined" style={{ marginTop: '10px' }}>
               <InputLabel id="fps-select-label">Current FPS</InputLabel>
               <Select
                  labelId="fps-select-current-label"
                  id="fps-select-current"
                  value={FPS}
                  label="Current FPS"
               >
                  <MenuItem value={FPS}>
               <span style={{ color: fpsColor }}>
                        {FPS}
                    </span>
                     &nbsp;/ {desiredFPS}</MenuItem>
               </Select>
            </FormControl>
         </Grid>
*/}
         <Grid item>
            <FormControl variant="outlined" style={{ marginTop: '10px' }}>
               <InputLabel id="fps-select-label">Desired FPS</InputLabel>
               <Select
                  labelId="fps-select-label"
                  id="fps-select"
                  value={desiredFPS}
                  onChange={handleFPSChange}
                  label="Desired FPS"
               >
                  <MenuItem value={24}>24 FPS</MenuItem>
                  <MenuItem value={30}>30 FPS</MenuItem>
                  <MenuItem value={48}>48 FPS</MenuItem>
                  <MenuItem value={60}>60 FPS</MenuItem>
                  <MenuItem value={120}>120 FPS</MenuItem>
               </Select>
            </FormControl>
         </Grid>
      </>
   );
};
export const BitRateManager = () => {
   const {
      state: { desiredVideoBitRate, desiredAudioBitRate },
      actions: { setDesiredVideoBitRate, setDesiredAudioBitRate },
   } = useContext(StudioContext);
   const handleVideoBitRateChange = (event) => {
      setDesiredVideoBitRate(event.target.value);
   };
   const handleAudioBitRateChange = (event) => {
      setDesiredAudioBitRate(event.target.value);
   };
   return (
      <>
         <Grid item>
            <FormControl variant="outlined" style={{ marginTop: '10px' }}>
               <InputLabel id="video-bitrate-select-label">Video BitRate</InputLabel>
               <Select
                  labelId="video-bitrate-select-label"
                  id="video-bitrate-select"
                  value={desiredVideoBitRate}
                  onChange={handleVideoBitRateChange}
                  label="Video BitRate"
               >
                  <MenuItem value={800000} title="800 kbps: Low quality or older content. Suitable for slow internet connections.">360p (30fps)</MenuItem>
                  <MenuItem value={1500000} title="1.5 Mbps: Standard definition. Suitable for most internet connections and offers reasonable quality.">480p (30fps)</MenuItem>
                  <MenuItem value={3000000} title="3 Mbps: High Definition. Requires a faster internet connection for smooth streaming.">720p (30fps)</MenuItem>
                  <MenuItem value={4500000} title="4.5 Mbps: High Definition at higher frame rate. Good for faster action videos.">720p (60fps)</MenuItem>
                  <MenuItem value={6000000} title="6 Mbps: Full High Definition. Suitable for larger screens with good internet connections.">1080p (30fps)</MenuItem>
                  <MenuItem value={9000000} title="9 Mbps: Full High Definition at higher frame rate. Ideal for sports and fast-paced content.">1080p (60fps)</MenuItem>
                  <MenuItem value={13000000} title="13 Mbps: 2K resolution. Provides sharper visuals for larger screens and high-end devices.">1440p (30fps)</MenuItem>
                  <MenuItem value={18000000} title="18 Mbps: 2K resolution at higher frame rate. Smooth visuals with great detail.">1440p (60fps)</MenuItem>
                  <MenuItem value={34000000} title="34 Mbps: Ultra High Definition. Ideal for large screens and premium viewing experiences.">4K (30fps)</MenuItem>
                  <MenuItem value={50000000} title="50 Mbps: Ultra High Definition at higher frame rate. Top-tier quality for the best devices and connections.">4K (60fps)</MenuItem>

               </Select>
            </FormControl>
         </Grid>
   
         <Grid item>
            <FormControl variant="outlined" style={{ marginTop: '10px' }}>
               <InputLabel id="audio-bitrate-select-label">Audio BitRate</InputLabel>
               <Select
                  labelId="audio-bitrate-select-label"
                  id="audio-bitrate-select"
                  value={desiredAudioBitRate}
                  onChange={handleAudioBitRateChange}
                  label="Audio BitRate"
               >
                  <MenuItem value={48000} title="48 kbps: Lower audio quality suitable for speech and voice.">48 kbps</MenuItem>
                  <MenuItem value={64000} title="64 kbps: Slightly better quality for speech and less complex audio.">64 kbps</MenuItem>
                  <MenuItem value={96000} title="96 kbps: Suitable for music and general audio playback.">96 kbps</MenuItem>
                  <MenuItem value={128000} title="128 kbps: Standard quality for most music streaming services. Good balance of quality and file size.">128 kbps</MenuItem>
                  <MenuItem value={192000} title="192 kbps: Improved quality for music and audio playback, suitable for higher-end streaming.">192 kbps</MenuItem>
                  <MenuItem value={256000} title="256 kbps: High-quality audio suitable for critical listening on good speakers or headphones.">256 kbps</MenuItem>
                  <MenuItem value={320000} title="320 kbps: Top-tier audio quality, often used for studio recordings and audiophile tracks.">320 kbps</MenuItem>
               </Select>
            </FormControl>
         </Grid>
      </>
   );
};
export const SessionManager = () => {
   const {
      state: { selectedCamera, screenStream, mediaRecorderRef, videoEditorRef, recording, videoBlob, canvasRef, audioContext, gainNode, desiredFPS, desiredVideoBitRate, desiredAudioBitRate },
      actions: { setRecording, setVideoBlob, setFPS, setAudioPermissionGranted, switchCameraStream, setupStream, enqueueSnackbar },
   } = useContext(StudioContext);
   
   let frameCount = 0;
   let isDrawing = true;
   const startDrawingToCanvas = (videoElement) => {
      const canvas = canvasRef.current;
      if (canvas && isDrawing) {
         const ctx = canvas.getContext('2d');
         
         const draw = () => {
            if (!isDrawing) return;
            
            if (videoElement) {
               ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
               frameCount++; // Increment frame counter
               requestAnimationFrame(draw);
            }
         };
         
         setInterval(() => {
            setFPS(frameCount); // Set the FPS in the context to the counted frames
            frameCount = 0; // Reset counter every second
         }, 1000);
         
         draw();
      }
   };
   const clearCanvas = useCallback(() => {
      const canvas = canvasRef.current;
      if (canvas) {
         const ctx = canvas.getContext('2d');
         ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      
      // Clear video source
      setVideoBlob(null);
      /*
      if (videoEditorRef.current) {
         videoEditorRef.current.srcObject = null;
         videoEditorRef.current.currentTime = 0;
      }
   
      // Stop all active tracks
      if (videoEditorRef.current && videoEditorRef.current.srcObject) {
         const tracks = videoEditorRef.current.srcObject.getTracks();
         tracks.forEach(track => track.stop());
         videoEditorRef.current.srcObject = null;
      }*/
   
      // Reset or recreate MediaRecorder
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
         mediaRecorderRef.current.stop();
         mediaRecorderRef.current = null;
      }
   
/*      if (screenStream) {
         console.log('here ', screenStream)
         //setupStream(screenStream);
         switchCameraStream(screenStream)
      }else if (selectedCamera) {
         switchCameraStream(selectedCamera)
      }*/
      
   },[screenStream, selectedCamera]);
   
   const handleStartRecording = useCallback(async () => {
     clearCanvas()
      try {
         // Ensure microphone permissions.
         const hasAudioPermissions = await ensureAudioPermissions(setAudioPermissionGranted, desiredAudioBitRate);
         if (!hasAudioPermissions) {
            enqueueSnackbar('Microphone access denied, please check permissions.', {variant:'error'});
            return;
         }
         // Ensuring the AudioContext is running.
         if (audioContext.current && audioContext.current.state === "suspended") {
            await audioContext.current.resume();
         }
         
         // Capture audio directly from the microphone.
         const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true,
               noiseSuppression: true,
               sampleRate: desiredAudioBitRate || 44100 } });
         //console.log(audioStream.getAudioTracks());
         
         if (audioContext && audioStream && audioStream.getAudioTracks().length > 0) {
            const audioSource = audioContext.current.createMediaStreamSource(audioStream);
            audioSource.connect(gainNode.current);
         }
         
         const destination = audioContext.current.createMediaStreamDestination();
         gainNode.current.connect(destination);
         
         // Control the gain (volume) if you need to
         gainNode.current.gain.value = 1.0; // Default is 1 (no change); > 1 is amplification, < 1 is attenuation.
         
         // Capture video from the canvas.
         let canvasStream;
         if (canvasRef) {
            //console.log("confirm canvasRef.current ", canvasRef);
            canvasStream = canvasRef.current.captureStream(desiredFPS);
         } else {
            console.error("Canvas is not available, cannot capture its stream.", canvasRef);
         }
         const combinedStream = new MediaStream([
            ...(canvasStream ? canvasStream.getVideoTracks() : []),
            ...destination.stream.getAudioTracks(),
         ]);
         //console.log([...combinedStream.getAudioTracks(), ...combinedStream.getVideoTracks()]);
   
         if (videoEditorRef.current) {
            videoEditorRef.current.play(); // now this line fails
            videoEditorRef.current.muted = true; // Since we're capturing audio directly from the mic.
         } else {
            console.error("Video Editor Reference is not available.");
         }
   
         
         mediaRecorderRef.current = new MediaRecorder(combinedStream,{
            bitsPerSecond: desiredVideoBitRate + desiredAudioBitRate
         });
         
         mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
               setVideoBlob(event.data);
               videoEditorRef.current.srcObject = null;
               videoEditorRef.current.src = URL.createObjectURL(event.data);
               videoEditorRef.current.load();
            }
         };
         
         mediaRecorderRef.current.start();
         setRecording(true);
         startDrawingToCanvas(videoEditorRef.current);
      } catch (error) {
         console.error("Error during recording:", error);
      }
   },[canvasRef, screenStream]);
   const handleStopRecording = () => {
      if (mediaRecorderRef.current) {
         mediaRecorderRef.current.stop();
         //videoEditorRef.current.srcObject && videoEditorRef.current.srcObject.getTracks().forEach(track => track.stop()); // problematic for screen?
         videoEditorRef.current.muted = false;
         setRecording(false);
         isDrawing = false
      }
   };
   const handleDownload = () => {
      if (videoBlob) {
         const url = URL.createObjectURL(videoBlob);
         const a = document.createElement('a');
         a.href = url;
         a.download = `StudioCastFM_${getFormattedDateTime()}.webm`;
         a.click();
         URL.revokeObjectURL(url);
      }
   };
   return (
      <>
         <Divider/>
      <Grid item style={{marginTop:20}}>
      <Grid container spacing={1}>
         {!recording ? (
            <Button variant={'outlined'} onClick={handleStartRecording} style={{ marginRight: 20 }}>
               <Mic/>&nbsp; Start
            </Button>
         ) : (
            <Button variant={'outlined'} onClick={handleStopRecording} style={{ marginRight: 20 }}>
               <Stop/>&nbsp; Stop
            </Button>
         )}
         {videoBlob && (
            <Button variant={'outlined'} onClick={handleDownload}>
               <Download/>&nbsp; Download
            </Button>
         )}
         {(!selectedCamera) && <Grid container spacing={1}>
            <Typography color={'primary'} style={{marginTop: 20, marginLeft: 10}}><Info
               style={{fontSize: '22px'}}/>&nbsp;No Video Feed Selected.</Typography>
         </Grid>}
      </Grid>
      </Grid>
      </>
   );
};
export const VideoEditor = () => {
   const {
      state: { videoEditorRef, recording, canvasRef, desiredVideoBitRate},
   } = useContext(StudioContext);
   let width, height;
   switch (desiredVideoBitRate) {
      case 800000:
         width = 640; // typical width for 360p
         height = 360;
         break;
      case 1500000:
         width = 854; // typical width for 480p
         height = 480;
         break;
      case 3000000:
      case 4500000:
         width = 1280; // typical width for 720p
         height = 720;
         break;
      case 6000000:
      case 9000000:
         width = 1920; // typical width for 1080p
         height = 1080;
         break;
      case 13000000:
      case 18000000:
         width = 2560; // typical width for 1440p
         height = 1440;
         break;
      case 34000000:
      case 50000000:
         width = 3840; // width for 4K
         height = 2160;
         break;
      default:
         width = 1920; // default to 1080p
         height = 1080;
         break;
   }
   useEffect(() => {
      canvasRef.current.width = width;
      canvasRef.current.height = height;
   }, [desiredVideoBitRate, canvasRef, height, width]);
   
   return (
      <div style={{padding:20, margin:'auto', display:'block'}}>
         <video style={{margin:'auto', display:'block'}}
                ref={videoEditorRef} width="1080" height="607" controls={!recording}></video>
         <canvas ref={canvasRef} width={width} height={height} style={{ display: 'none' }}></canvas>
      </div>
   );
};
export const StudioContextProvider = ({ children, props }) => {
   const { enqueueSnackbar } = useSnackbar()
   const [cameras, setCameras] = useState([]);
   const [selectedCamera, setSelectedCamera] = useState(null);
   const [desiredFPS, setDesiredFPS] = useState(60);
   const [FPS, setFPS] = useState(0);
   const [desiredVideoBitRate, setDesiredVideoBitRate] = useState(3000000);
   const [desiredAudioBitRate, setDesiredAudioBitRate] = useState(64000);
   const [recording, setRecording] = useState(false);
   const [videoBlob, setVideoBlob] = useState(null);
   const videoRefs = useRef([]);
   const videoEditorRef = useRef(null);
   const mediaRecorderRef = useRef(null);
   const screenRef = useRef(null);
   const [screenStream, setScreenStream] = useState(null);
   const [cameraStreams, setCameraStreams] = useState([]);
   const currentStreamRef = useRef(null); // to store the current video stream we're rendering to the canvas
   const canvasRef = useRef(null);
   const [audioPermissionGranted, setAudioPermissionGranted] = useState(false);
   const [audioDevices, setAudioDevices] = useState([]);
   const [volumes, setVolumes] = useState({});
   const audioContext = useRef(null);
   const gainNode = useRef(null);
   
   useEffect(() => {
      const stopMediaTracks = () => {
         // Cleanup for cameraStreams
         cameraStreams.forEach(stream => {
            if (stream) {
               stream.getTracks().forEach(track => {
                  track.stop();
               });
            }
         });
         
         // Cleanup for screenStream
         if (screenStream) {
            screenStream.getTracks().forEach(track => track.stop());
         }
      };
      
      // Register the event
      window.addEventListener('beforeunload', stopMediaTracks);
      
      // Unregister the event when component unmounts
      return () => {
         window.removeEventListener('beforeunload', stopMediaTracks);
      };
   }, []);  // Empty array for effect to run on mount and unmount only
   
   
   useEffect(() => {
      // Check if audioContextRef is not already initialized
      if (!audioContext.current) {
         audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      }
      // Check if gainNodeRef is not already initialized
      if (!gainNode.current) {
         gainNode.current = audioContext.current.createGain();
      }
      return () => {
         // Cleanup (if needed, e.g., closing the audio context)
         // audioContextRef.current.close();
      };
   }, []); // Empty dependency array ensures this runs once after the initial render
   
   const switchCameraStream = async (camera) => {
      try {
         if (videoEditorRef.current) {
            let stream = await navigator.mediaDevices.getUserMedia({
               video: { deviceId: camera.deviceId,
                  aspectRatio: 16/9,
                  //width: { ideal: 1280 },  // Example for 720p
                  //height: { ideal: 720 }
               }
            });
            
            // If there's an existing stream in the video editor, take its audio track
            if (videoEditorRef.current.srcObject) {
               const audioTracks = videoEditorRef.current.srcObject.getAudioTracks();
               if (audioTracks.length > 0) {
                  stream.addTrack(audioTracks[0]);
               }
            }
            
            videoEditorRef.current.srcObject = stream;
            videoEditorRef.current.onloadedmetadata = () => {
               videoEditorRef.current.play().catch(error => {
                  console.error("Error playing video:", error);
               });
            };
         }
         currentStreamRef.current = videoEditorRef.current.srcObject;
         setSelectedCamera(camera)
   
      } catch (error) {
         console.error("Error switching camera stream:", error);
      }
   };
   const setupStream = (stream) => {
      if (videoEditorRef.current) {
         // Combine audio from existing stream if present
         if (videoEditorRef.current.srcObject) {
            const audioTracks = videoEditorRef.current.srcObject.getAudioTracks();
            if (audioTracks.length > 0) {
               stream.addTrack(audioTracks[0]);
               console.error("Found audio track for:", audioTracks[0]);
            }else{
               console.error("No audio track for:", audioTracks);
            }
         }
         
         videoEditorRef.current.srcObject = stream;
         videoEditorRef.current.onloadedmetadata = () => {
            videoEditorRef.current.play().catch(error => {
               console.error("Error playing video:", error);
            });
         };
      }
      currentStreamRef.current = videoEditorRef.current.srcObject;
      setSelectedCamera(stream)
   };
   
   return (
      <StudioContext.Provider
         value={{
            actions: {
               setCameras,
               setSelectedCamera,
               setRecording,
               setVideoBlob,
               setScreenStream,
               setCameraStreams,
               switchCameraStream,
               setupStream,
               setAudioDevices,
               setVolumes,
               setDesiredFPS,
               setFPS,
               setDesiredVideoBitRate,
               setDesiredAudioBitRate,
               setAudioPermissionGranted,
               enqueueSnackbar
            },
            state: {
               cameras,
               selectedCamera,
               videoEditorRef,
               mediaRecorderRef,
               videoRefs,
               recording,
               videoBlob,
               screenStream,
               screenRef,
               canvasRef,
               cameraStreams,
               audioContext,
               gainNode,
               volumes,
               audioDevices,
               desiredFPS,
               FPS,
               desiredVideoBitRate,
               desiredAudioBitRate,
               audioPermissionGranted
               
            },
         }}
         {...props}
      >
         {children}
      </StudioContext.Provider>
   );
};
const MonitorIcon = ({onClick}) => (
   <svg width="384" height="216" viewBox="0 0 24 24" onClick={onClick} style={{margin:'10px auto -10px',display:'block'}}>
      <path d="M4 4h16v10H4z" fill="#e0e0e0"/>
      <path d="M2 2v12h20V2H2zm2 2h16v10H4V4zm-1 12v2h18v-2H3zm1 2h16v1H4v-1z" fill="#a0a0a0"/>
   </svg>
);
const ensureAudioPermissions = async (setAudioPermissionGranted, desiredAudioBitRate) => {
   try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true,
            noiseSuppression: true,
            sampleRate: desiredAudioBitRate || 44100 } });
      
      // If permissions are granted, stop the stream immediately.
      // We just wanted to check permissions, not start recording yet.
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
   
      setAudioPermissionGranted(true); // set permissions as granted
      return true; // permissions granted
   } catch (error) {
      console.error("Error accessing microphone:", error);
      return false; // permissions denied or another error occurred
   }
};
const getFormattedDateTime = () => {
   const date = new Date();
   const year = date.getFullYear();
   const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
   const day = String(date.getDate()).padStart(2, '0');
   const hours = String(date.getHours()).padStart(2, '0');
   const minutes = String(date.getMinutes()).padStart(2, '0');
   const seconds = String(date.getSeconds()).padStart(2, '0');
   
   return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}
export default Studio;
