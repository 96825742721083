
export const ENVIRONMENT = [
  // always point native to production
  ['production', location.origin.includes('capacitor://localhost')],
  ['development', !location.origin.includes('capacitor://localhost') && !location.origin.includes('studiocast.fm')],
  ['staging', location.origin.includes('staging.studiocast.fm')],
  ['production', !location.origin.includes('staging.studiocast.fm') && location.origin.includes('studiocast.fm')]
].find(([name, isActive]) => isActive)[0]
export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3001`,
    staging: `https://${subdomain}.staging.studiocast.fm`,
    production: `https://${subdomain}.studiocast.fm`
  }[ENVIRONMENT])

export const getClientHost = () =>
  ({
    development: `http://localhost:3000`,
    staging: `https://staging.studiocast.fm`,
    production: `https://studiocast.fm`
  }[ENVIRONMENT])

export const API_HOST = {
  //development: 'http://localhost:5005/api/v1',
  development: 'https://api.studiocast.fm/api/v1',
  staging: 'https://staging.api.formulatelabs.com/v1',
  production: 'https://api.studiocast.fm/api/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx'
}[ENVIRONMENT]
export const INDUSTRY_SECTORS = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing"
];
export const pricingData = [
  {
    "plan": "Free",
    "price": 0,
    "annual_price": 0,
    "aiResponses": 50,
    "faq": 0,
    "aiPersonalities": 1,
    "otherFeatures": ["AI-Response Only"],
    "dev_payment_link": "https://example.com/payment/free",
    "dev_annual_payment_link": "https://example.com/payment/free/annual",
    
    "payment_link": "https://example.com/payment/free",
    "annual_payment_link": "https://example.com/payment/free/annual",
    "isVisible": false
  },
  {
    "plan": "Starter",
    "price": 19,
    "annual_price": Math.round(19 * 0.75),
    "aiResponses": 250,
    "faq": 10,
    "aiPersonalities": 3,
    "otherFeatures": ["Auto-Translation", "Basic Analytics"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
    
    "payment_link": "https://buy.stripe.com/4gw9CufHsgJxdeo4gg",
    "annual_payment_link": "https://buy.stripe.com/4gwbKC8f0ctha2ceUV",
    "isVisible": true
  },
  {
    "plan": "Basic",
    "price": 49,
    "annual_price": Math.round(49 * 0.75),
    "aiResponses": 500,
    "faq": 50,
    "aiPersonalities": 5,
    "otherFeatures": ["Slack Integration", "Status Message", "Email Notifications"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/3cs01U1QC50PgqA5kn",
    "annual_payment_link": "https://buy.stripe.com/4gw3e61QC78Xfmw002",
    "isVisible": true
  },
  {
    "plan": "Growth",
    "price": 99,
    "annual_price": Math.round(99 * 0.75),
    "aiResponses": '1,000',
    "faq": 100,
    "aiPersonalities": 11,
    "otherFeatures": ["White-labeling", "Email Capture"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/28o3e6brc8d1eis4gk",
    "annual_payment_link": "https://buy.stripe.com/7sI8yq3YKdxl5LW6ot",
    "isVisible": true
  },
  {
    "plan": "Pro",
    "price": 199,
    "annual_price": Math.round(199 * 0.75),
    "aiResponses": '2,500',
    "faq": "Unlimited",
    "aiPersonalities": 'Custom',
    "otherFeatures": ["Phone Capture", "Webhooks", "Advanced Analytics"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/7sI9Cu7aW3WLb6g9AG",
    "annual_payment_link": "https://buy.stripe.com/28obKCeDogJx0rCdQX",
    "isVisible": true
  },
  {
    "plan": "Enterprise",
    "price": "$$$",
    "annual_price": "$$$",
    "aiResponses": "Unlimited",
    "faq": "Unlimited",
    "aiPersonalities": "Custom",
    "otherFeatures": ["Custom Integrations", "Priority Support", "Dedicated Account Manager"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://example.com/payment/enterprise/monthly",
    "annual_payment_link": "https://example.com/payment/enterprise/annual",
    "isVisible": false
  }
]

export const COLORS = ['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']
