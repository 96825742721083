import * as React from 'react';
import {
  Button, FormHelperText,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText, Menu, MenuItem,
  Paper,
  TextField, Tooltip,
  Typography,
} from '@mui/material';
import {Add, Close, FilterList, Person} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import {useContext, useMemo, useState} from 'react';
import {prettyPhone, toCapitalizeFromUnderScore, toUnderScoreSlug} from '../../../lib/helpers/helpers';
import { useParams } from 'react-router';
import {DemoContext} from "../../../lib/contexts/DemoContext";
import useContacts from "../../../lib/hooks/useContacts";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  rootManagement: {
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  containerTop: {
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  container: {
   // maxHeight: '40vh',
    overflowY: 'auto',
  },
  chip: {
    margin: 8,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.paperText,
  },
  button: status => {
    switch (status) {
      case 0:
        return { borderColor: '#F5AB1E' }; // Draft color
      case -2:
        return { borderColor: '#C22F2E' }; // Archived color
      case -1:
        return { borderColor: '#9C9C9C' }; // Inactive color
      case 1:
        return { borderColor: '#3DAF61' }; // Published color
      default:
        return {};
    }
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tag, contactId } = useParams();
  
  const {
    actions: { handleNewFAQ },
    state: { matchSM },
  } = useContext(DemoContext);
  
  const {
    isLoading: isLoadingFaq,
    data: contacts,
    refetch: refetchFaq,
  } = useContacts();
  
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (item) => {
    setStatusFilter(item);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const filteredContacts = useMemo(() => {
    return (
       contacts?.filter(
        item => statusFilter === 'all' ?
          item.first_name?.toLowerCase().includes(search.toLowerCase()) ||
          item.last_name?.toLowerCase().includes(search.toLowerCase()) ||
          item.email?.toLowerCase().includes(search.toLowerCase()) ||
          item.phone_number?.toLowerCase().includes(search.toLowerCase())
         :
           statusFilter === 'email' && item.email && item.email?.toLowerCase().includes(search.toLowerCase()) ||
           statusFilter === 'phone_number' && item.phone_number && item.phone_number?.toLowerCase().includes(search.toLowerCase()),
      ) || []
    );
  }, [contacts, search, statusFilter]);
  
  const handleListItemClick = (eventType, item) => {
    if (eventType === 'hashtag') {
      navigate(`../dashboard/contacts/tag/${toUnderScoreSlug(item)}`, { replace: true });
    } else {
      navigate(`../dashboard/contacts/${item.id}`, { replace: true });
    }
  };

  return (
    <Paper className={classes.rootManagement}>
      <TextField
        style={{ marginTop: 40, marginBottom: 0 }}
        autoFocus={true}
        fullWidth={true}
        variant="outlined"
        label="Search for..."
        placeholder=""
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          endAdornment: search ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search filter"
                onClick={() => setSearch('')}
                size="large">
                <Close className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
      />
      
      <Typography variant={'subtitle1'} style={{ marginTop: 40, marginBottom: 20 }}>
        All Contacts
        <>
          <IconButton style={{float:'right'}} onClick={handleMenuClick} size="large">
            <Tooltip title={'Filter by status'}>
              <FilterList/>
            </Tooltip>
          </IconButton>
          <Button variant={'outlined'} color={'primary'} style={{display: matchSM ? 'none' : 'none', marginTop: 10, marginLeft: matchSM ? 'auto' : 0, marginRight: matchSM ? 'auto' : 10, float: matchSM ? 'none' : 'right'}} onClick={handleNewFAQ}>
            <Tooltip title={'Add Item'}>
              <><Add/>&nbsp;New Item</>
            </Tooltip>
          </Button>
        </>
      </Typography>
      <Menu
         anchorEl={anchorEl}
         open={Boolean(anchorEl)}
         onClose={handleClose}
      >
        {['all', 'email', 'phone_number'].map((item) => (
           <MenuItem key={item} onClick={() => handleMenuItemClick(item)}>
             <Typography>
               {toCapitalizeFromUnderScore(item)}
             </Typography>
           </MenuItem>
        ))}
      </Menu>
      <List component="nav" aria-label="faq sidebar list" className={classes.container}  style={{
        height: matchSM ? 350 : 564}}>
  
        {!filteredContacts.length && <FormHelperText style={{margin: 'auto', textAlign:'center'}}>None found, check back later.</FormHelperText>}
        
        {filteredContacts &&
          filteredContacts.map(item => (
            <ListItem
              key={item.id}
              button
              selected={contactId == item.id}
              onClick={() => handleListItemClick('contact', item)}
            >
              {!matchSM && <ListItemIcon>
                <Person className={classes.icon}/>
              </ListItemIcon>}
              <ListItemText
                 style={matchSM ? {maxWidth: '100%',display:'block'} : {}}
                 primary={
                 (item.first_name ?? '') +
                 (item.first_name && item.last_name ? ' ' : '') +
                 (item.last_name ?? '')
              }
                 secondary={
                   item.email ? item.email : '' +
                   item.email && item.phone_number + ' | ' +
                   item.phone_number ? prettyPhone(item.phone_number) : ''
                 }
              />
            </ListItem>
          ))}
      </List>
    </Paper>
  );
}
